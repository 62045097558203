.radio-button-card {
  float: left;
  margin: 5px 5px;
  width: 48%;
  height: 81px;
  position: relative;
  border: 1px solid #2196f3;
  box-sizing: border-box;
  border-radius: 8px;
}
.radio-button-label-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}
.radio-button-label,
.radio-button-input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
}

.radio-button-input[type='radio'] {
  opacity: 0.011;
  z-index: 100;
}

.radio-button-input[type='radio']:checked + .radio-button-label {
  background: #e3f2fd;
}

.radio-button-label {
  padding: 15px;
  cursor: pointer;
  z-index: 90;
}

.radio-button-label:hover {
  background: #ddd;
}
