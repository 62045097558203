.pz-navbar {
  display: inline-block;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.3s;
  > i {
    vertical-align: middle;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
  .avatar {
    margin-right: 8px;
  }
  margin-left: auto;
}
